<template>
<a :class="'nav-item nav-link ' + this.active" :id="this.id+'-tab'" role="tab" aria-selected="true">
    <div class="wizard-step-icon">{{this.numberCard}}</div>
    <div class="wizard-step-text">
        <div class="wizard-step-text-name">{{this.title}}</div>
        <div class="wizard-step-text-details">{{this.subTitle}}</div>
    </div>
</a>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    components: {},
    mounted: function () {},
    methods: {},
    computed: {},
    props: {
        id: String,
        numberCard: String,
        title: String,
        subTitle: String,
        active: String,
    }
}
</script>>
