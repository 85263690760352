<template>
<div class="modal fade" :id="'modalDireccion'+this.id" tabindex="-1" role="dialog" aria-labelledby="modalDireccion" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" style="padding: 0px 15px;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">Direcci&oacute;n</h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <label class="text-gray-600 small " for="emailExample">C&oacute;digo Postal</label>
                        <input :id="'domicilio_cp_'+this.id" aria-label="Código Postal" @keypress="isNumber($event)" v-on:keyup="getDomicilio()" :class="'form-control send-data-register-domicilio-'+this.id" type="text" />
                    </div>
                    <div class="col-md-6"></div>
                    <div class="col-md-6 mt-2">
                        <label class="text-gray-600 small" for="emailExample">Provincia</label>
                        <select @change="getDomicilio()" aria-label="Provincia" :class="'form-control send-data-register-domicilio-'+this.id" :id="'domicilio_provincia_'+this.id">
                            <option selected disabled></option>
                            <option value="1">CAPITAL FEDERAL</option>
                            <option value="2">BUENOS AIRES</option>
                            <option value="24">LA RIOJA</option>
                            <option value="3">CATAMARCA</option>
                            <option value="4">CORDOBA</option>
                            <option value="5">CORRIENTES</option>
                            <option value="6">CHACO</option>
                            <option value="7">CHUBUT</option>
                            <option value="8">ENTRE RIOS</option>
                            <option value="9">FORMOSA</option>
                            <option value="10">JUJUY</option>
                            <option value="11">LA PAMPA</option>
                            <option value="12">MENDOZA</option>
                            <option value="13">MISIONES</option>
                            <option value="14">NEUQUEN</option>
                            <option value="15">RIO NEGRO</option>
                            <option value="16">SALTA</option>
                            <option value="17">SAN JUAN</option>
                            <option value="18">SAN LUIS</option>
                            <option value="19">SANTA CRUZ</option>
                            <option value="20">SANTA FE</option>
                            <option value="21">SANTIAGO DEL ESTERO</option>
                            <option value="22">TIERRA DEL FUEGO</option>
                            <option value="23">TUCUMAN</option>
                        </select>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="text-gray-600 small " for="emailExample">Localidad / Barrio</label>
                        <input :id="'domicilio_localidad_'+this.id" aria-label="Localidad / Barrio" name="domicilio_localidad" :class="'form-control send-data-register-domicilio-'+this.id" type="text" />
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="text-gray-600 small " for="emailExample">Calle</label>
                        <input :id="'domicilio_calle_'+this.id" name="domicilio_calle" aria-label="Calle" :class="'form-control send-data-register-domicilio-'+this.id" type="text" />
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="text-gray-600 small " for="emailExample">N&uacute;mero</label>
                        <input @keypress="isNumber($event)" :id="'domicilio_numero_'+this.id" aria-label="Número" :class="'form-control send-data-register-domicilio-'+this.id" type="text" />
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="text-gray-600 small " for="emailExample">Piso / Departamento (opcional)</label>
                        <input :id="'domicilio_piso_'+this.id" name="domicilio_piso" class="form-control" type="text" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" :id="'closeModalDireccion_'+this.id" style="background-color:grey;color:white" type="button" data-bs-dismiss="modal">Cerrar</button>
                <button class="btn btn-primary" type="button" style="background:#002f87 !important" @click="sendDomicilio">Guardar</button>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}

.modal-xl {
    max-width: 1330px !important;
}

.btn-ref-guardia-pasiva {
    background-color: #25B4BD;
    padding: 1px 1px 0px 20px;
    border-radius: 30px;
    margin-right: 5px;
}

.btn-ref-guardia-activa {
    background-color: #457b9d;
    padding: 1px 1px 0px 20px;
    border-radius: 30px;
    margin-right: 5px;
}
</style>

<script>
import $ from "jquery";
import router from '@/router/index.js' // Vue router instance
import Vue from 'vue'

export default {
    components: {},
    mounted: function () {
        this.loadData();
    },
    methods: {
        loadData() {
            if (this.value != null && this.value.length > 0) {
                this.$store.dispatch("user/getDireccionModal", this.value).then((response) => {
                    this.$store.commit("SET_BLOQUEADO", false);
                    $("#domicilio_numero_" + this.id).val(response.numero);
                    $("#domicilio_piso_" + this.id).val(response.piso);
                    $("#domicilio_provincia_" + this.id).val(response.provincia);
                    $("#domicilio_cp_" + this.id).val(response.codigo_postal);
                    $("#domicilio_localidad_" + this.id).val(response.localidad);
                    $("#domicilio_calle_" + this.id).val(response.calle);
                }).catch((error) => {
                    this.$store.commit("SET_BLOQUEADO", false);
                    Vue.swal("Portal de Proveedoras/es", error.response.data, "error")
                })
            }
        },
        sendDomicilio() {
            try {
                this.validateForm();
                this.saveData();
            } catch (error) {
                Vue.swal("Portal Proveedores/as", error, "info")
            }
        },
        saveData() {
            let data = new Object();
            data.numero = $("#domicilio_numero_" + this.id).val();
            data.piso = $("#domicilio_piso_" + this.id).val();
            data.provincia = $("#domicilio_provincia_" + this.id).val();
            data.codigo_postal = $("#domicilio_cp_" + this.id).val();
            data.localidad = $("#domicilio_localidad_" + this.id).val();
            data.calle = $("#domicilio_calle_" + this.id).val();
            this.$store.dispatch("user/sendDireccion", data).then((response) => {
                $("#" + this.codigo).val(response);
                this.setValuesInput();
                document.getElementById("closeModalDireccion_" + this.id).click();
            }).catch((error) => {
                Vue.swal("Portal de Proveedoras/es", error.response.data, "error")
            })
        },
        setValuesInput() {

            let calle = document.getElementById("domicilio_calle_" + this.id);
            if (calle.type == "select-one") {
                calle = calle.options[calle.selectedIndex].text;
            } else {
                calle = document.getElementById("domicilio_calle_" + this.id).val();
            }
            let numero = $("#domicilio_numero_" + this.id).val()
            let piso = $("#domicilio_piso_" + this.id).val();
            let provncia = document.getElementById("domicilio_provincia_" + this.id);
            var selProvncia = provncia.options[provncia.selectedIndex].text;
            let cp = $("#domicilio_cp_" + this.id).val();
            $("#" + this.id).val(calle + " " + numero + " " + piso + " ." + selProvncia + "(" + cp + ")");
        },
        validateForm() {
            $('.send-data-register-domicilio-' + this.id).each(function (i, obj) {
                if (!($(this).val() != null && $(this).val().length >= 1)) {
                    throw ("El campo " + this.ariaLabel + " es obligatorio");
                }
            });
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        getDomicilio() {

            let codPostal = $("#domicilio_cp_" + this.id).val();
            let provincia = document.getElementById('domicilio_provincia_' + this.id).options[document.getElementById('domicilio_provincia_' + this.id).selectedIndex].value;
            if (codPostal.length > 3 && provincia.length > 0) {
                this.$store.dispatch("proveedor/getDireccion", [codPostal, provincia]).then((res) => {

                    let domicilios = this.$store.state.proveedor.domicilio_real
                    // si es es capital, seteo las calles
                    this.callesCapital(domicilios, codPostal)

                });
            }
        },

        callesCapital(domicilios, codPostal) {
            if (codPostal >= 1000 && codPostal <= 1499) {
                let calles = '';
                $.each(domicilios, function (index, value) {
                    calles = calles + `<option value="` + value.codigo + `">` + value.calle + `</option> `;
                });
                $("#domicilio_calle_" + this.id).replaceWith(`<select class="form-control psCotiDato enviar-persona" alt="El campo calle del domicilio real es obligatorio." id="domicilio_calle_` + this.id + `" name="domicilio_calle">` + calles + `</select>`);
            } else {
                $("#domicilio_calle_" + this.id).replaceWith('<input class="form-control  send-data-register" type="text" id="domicilio_calle" name="domicilio_calle_' + this.id + '" placeholder="" aria-label="tipo de prestador" />');
            }
        },
        resetCamposCalle(id) {
            $("#domicilio_cp_" + this.id).val('');
            $("#domicilio_provincia_" + this.id).val('');
            $("#domicilio_numero_" + this.id).val('');
            $("#domicilio_piso_" + this.id).val('');
            $("#domicilio_departamento_" + this.id).val('');
            $("#domicilio_calle_" + this.id).replaceWith('<input class="form-control  send-data-register" type="text" id="domicilio_" name="calle_' + id + '" placeholder="" />');
        },
    },
    computed: {},
    props: {
        id: String,
        codigo: String,
        value: String,
    }
}
</script>
