<template>
<div>
    <div class="col-md-12 text-end mb-2">
        <a class="btn btn-primary color-btn lift lift-sm" v-if="valor && valor.length > 2" style="background-color:#002f87 !important;border:none;height:33px;" @click="downloadXLS">Descarga Excel</a>
    </div>
    <Table
        :tableConfig="tableConfig"
        :tableData="tableData"
        :maxRows="15"
        @navigation="handleNavigation">
    </Table>
</div>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}
</style>

<script>

import Vue from 'vue'
import Table from '@/components/form/tables/Table.vue';

export default {
    name: 'GrillaGenerica',
    components: {
        Table
    },
    props: { 
        dato: Array, 
        valor: String,
        idTramite: Number,
        codigoAtributo: String,
        deft_tramite_estado_id:String,
        field: Object
    },
    data: () => ({
        tableConfig: [],
        tableData: [],
        navigationConfig: null
    }),
    mounted: function () {
        this.generateTableConfig();
        this.generateTableData();
    },
    watch: {
        // whenever question changes, this function will run
        valor(newValor, oldValor) {
            this.generateTableData();
        }
    },
    methods: {
        generateTableConfig(){
            const actionsConfig = this.dato.filter(item => {
                return item.clave.toUpperCase().includes("NAVEGACIONUI");
            });

            if (actionsConfig.length > 0) {
                this.navigationConfig = this.getNavigationConfig(actionsConfig);
            }

            this.tableConfig = this.dato.filter(item => {
                return !item.clave.toUpperCase().includes("NAVEGACIONUI");
            }).map( (item) => {
                
                if(item.valor == 'index' || item.valor == 'id'){
                    return {
                        prop: '_index', 
                        name: '#',
                        class: '-index'
                    }
                }

                return {
                    prop: item.valor.toLowerCase().replaceAll(' ', '_').replaceAll('/', '_').normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
                    name: item.descripcion,
                    class: '-static'
                }
            });


            if (this.navigationConfig) {
                    this.tableConfig.push({
                    prop: '_navigation', 
                    name: 'Navegación',
                    class: '-navigation'
                })
            }

        },
        generateTableData(){
            let data;
            if(!this.valor){
                return;
            }

            //Validamos si es objeto o string
            try {
               data = JSON.parse(this.valor);
            } catch (e) {
                data = this.valor;
            }
            this.tableData = data.map( (item, index) => {
                let objBodyData = new Object;
                Object.keys(item).map(function(objectKey) {                  
                    objBodyData[objectKey.toLowerCase()] = item[objectKey];
                }); 
                return {
                    tableId: index,
                    ...objBodyData,
                }
            });
        },
        validateHeadButton(data){
            let atributoOrigen;
            
            data.forEach(element => {
                if (element.clave.toUpperCase().includes('ATTORIGEN')) {
                    atributoOrigen = element.valor;
                }
            });
            if(atributoOrigen == null || atributoOrigen == '' || atributoOrigen.length == 0){
                return false;
            }else{
                return true;
            }
        },
        irTramite(dato, objRow) {
            let atributoOrigen;
            let atributoDestino;
            let tramiteDestino;
            dato.forEach(element => {
                if (element.clave.toUpperCase().includes('ATTORIGEN')) {
                    atributoOrigen = element.valor;
                }
                if (element.clave.toUpperCase().includes('TRAMITEDESTINO')) {
                    tramiteDestino = element.valor;
                }
                if (element.clave.toUpperCase().includes('ATTDESTINO')) {
                    atributoDestino = element.valor;
                }
            });
            if (atributoOrigen != null) {
                //busco la definicion del tramite con el tramite destino
                this.$store.dispatch("tramiteGenerico/getDeftTramiteByCode", tramiteDestino).then((response) => {
                    this.$store.commit('SET_ROUTER', "/home");
                    this.$store.commit("tramiteGenerico/SET_ICONTRAMITE", this.getIcon(response.tipo));
                    this.$store.commit("tramiteGenerico/SET_TRAMITESELECCIONADO", response.descripcion);
                    this.$store.commit("tramiteGenerico/SET_TRAMITESELECCIONADODESC", response.portal_leyenda);
                    this.$store.dispatch("tramiteGenerico/getTramiteEstados", this.getDataTramite(response, atributoOrigen, atributoDestino, objRow));
                }).catch((error) => {
                    Vue.swal("Portal de Proveedoras/es", JSON.stringify(error.response.data.mensaje), "error")
                })

            }
        },
        getIcon(data) {
            if (data == 'gestion') {
                return "fas fa-folder-plus";
            } else {
                return "fas fa-search";
            }
        },

        getDataTramite(data, atributoOrigen, atributoDestino, objRow) {
            let obj = new Object;
            obj.deft_tipo_tramite_id = data.id;
            obj.cart_usuario_id = this.user;
            obj.url = data.portal_strategy;
            obj.valor_atributo_origen = objRow[atributoOrigen.toUpperCase().trim()].trim().replace("$", "").replace(".", "").replace(",", ".");
            obj.atributo_destino = atributoDestino;
            obj.cart_empresa_id = this.$store.state.user.user.empresa.id
            return obj;
        },
        filtrarEnvioAtributo(clave) {
            if (clave.toUpperCase() != null && clave.toUpperCase().includes("NAVEGACIONUI")) {
                return false;
            } else {
                return true;
            }
        },
        getJsonParse(valor) {
            if (valor != null && valor.length > 2) {
                return JSON.parse(valor);
            } else {
                return valor;
            }
        },
        getDescripcion(atributo, objRow) {

        },
        getTd(atributo, objRow) {

            return objRow[atributo] + "&nbsp;";
        },
        //esta funcino deberia alinear los campos de la tabla segun el dato a mostrar
        //EJ -> si es fecha, deberia alinearlo al centro, si es importe a la derecha
        getAlignTd(atributo, objRow) {
            try {
                if (objRow[atributo].indexOf("href") > 0) {
                    return "text-end text-md-center"
                }
                if (this.getAlignDate(objRow[atributo])) {
                    return "text-end text-md-center"
                }

                if (objRow[atributo].indexOf("$") > -1) {
                    return "text-end"
                }
            } catch (error) {
                console.log("Error al intentar armar la grilla" + error)
            }
        },
        getAlignDate(data) {
            try {
                var date_regex = /^([0-2][0-9]|3[0-1])(\/|-)(0[1-9]|1[0-2])\2(\d{4})$/;
                return date_regex.test(data)
            } catch (error) {
                return false;
            }
        },
        downloadXLS() {
            let obj = new Object();
            obj.id_tramite = this.idTramite;
            obj.codigoAtributo = this.codigoAtributo;
            obj.field = this.field;
            this.$store.dispatch("descargas/descargaXlS", obj);
        },
        getNavigationConfig(optionArray) {
            let navigationConfig = {
                atributoOrigen: null,
                atributoDestino: null,
                tramiteDestino: null
            }

            optionArray.forEach(element => {
                if (element.clave.toUpperCase().includes('ATTORIGEN')) {
                    navigationConfig.atributoOrigen = element.valor;
                }
                if (element.clave.toUpperCase().includes('TRAMITEDESTINO')) {
                    navigationConfig.tramiteDestino = element.valor;
                }
                if (element.clave.toUpperCase().includes('ATTDESTINO')) {
                    navigationConfig.atributoDestino = element.valor;
                }
            });

            for (const key in navigationConfig) {
                const value = navigationConfig[key];
                if (!value || !value.trim()) {
                    return null;
                }
            }

            return navigationConfig;
        },
        handleNavigation(indexRow) {
            console.log(indexRow);

            const row = JSON.parse(this.valor)[indexRow];
            //console.log(row);
            //console.log();

            if (this.navigationConfig) {
                //busco la definicion del tramite con el tramite destino
                this.$store.dispatch("tramiteGenerico/getDeftTramiteByCode", this.navigationConfig.tramiteDestino).then((response) => {
                    this.$store.commit('SET_ROUTER', "/home");
                    this.$store.commit("tramiteGenerico/SET_ICONTRAMITE", this.getIcon(response.tipo));
                    this.$store.commit("tramiteGenerico/SET_TRAMITESELECCIONADO", response.descripcion);
                    this.$store.commit("tramiteGenerico/SET_TRAMITESELECCIONADODESC", response.portal_leyenda);
                    this.$store.dispatch("tramiteGenerico/getTramiteEstados", this.getDataTramite(response, this.navigationConfig.atributoOrigen, this.navigationConfig.atributoDestino, row));
                }).catch((error) => {
                    Vue.swal("Portal de Proveedoras/es", JSON.stringify(error.response.data.mensaje), "error")
                })

            }

        }
    },
    computed: {
        user: {
            get() {
                return this.$store.state.user.user.id;
            },
        },
        activoMenu: {
            get() {
                return this.$store.state.menu.activo;
            },
        }
    }
}
</script>
