<template>
<div class="modal fade" :id="'modalCalendar'+this.id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" style="padding: 0px 15px;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">Rango Horario</h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="overflow: auto; padding: 0px !important">
                <table :id="this.getIdtable()" :class="'table table-condensed table-bordered tabla_'+id ">
                    <thead>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none"></th>
                            <th>00:00</th>
                            <th>01:00</th>
                            <th>02:00</th>
                            <th>03:00</th>
                            <th>04:00</th>
                            <th>05:00</th>
                            <th>06:00</th>
                            <th>07:00</th>
                            <th>08:00</th>
                            <th>09:00</th>
                            <th>10:00</th>
                            <th>11:00</th>
                            <th>12:00</th>
                            <th>13:00</th>
                            <th>14:00</th>
                            <th>15:00</th>
                            <th>16:00</th>
                            <th>17:00</th>
                            <th>18:00</th>
                            <th>19:00</th>
                            <th>20:00</th>
                            <th>21:00</th>
                            <th>22:00</th>
                            <th>23:00</th>
                        </tr>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none"></th>
                            <th>01:00</th>
                            <th>02:00</th>
                            <th>03:00</th>
                            <th>04:00</th>
                            <th>05:00</th>
                            <th>06:00</th>
                            <th>07:00</th>
                            <th>08:00</th>
                            <th>09:00</th>
                            <th>10:00</th>
                            <th>11:00</th>
                            <th>12:00</th>
                            <th>13:00</th>
                            <th>14:00</th>
                            <th>15:00</th>
                            <th>16:00</th>
                            <th>17:00</th>
                            <th>18:00</th>
                            <th>19:00</th>
                            <th>20:00</th>
                            <th>21:00</th>
                            <th>22:00</th>
                            <th>23:00</th>
                            <th>24:00</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none">D</th>
                            <td id="D_00_01" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_01_02" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_02_03" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_03_04" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_04_05" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_05_06" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_06_07" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_07_08" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_08_09" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_09_10" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_10_11" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_11_12" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_12_13" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_13_14" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_14_15" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_15_16" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_16_17" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_17_18" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_18_19" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_19_20" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_20_21" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_21_22" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_22_23" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="D_23_24" :class="'calendar_value_'+this.id + ' calendar'"></td>

                        </tr>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none">L</th>
                            <td id="L_00_01" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_01_02" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_02_03" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_03_04" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_04_05" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_05_06" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_06_07" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_07_08" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_08_09" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_09_10" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_10_11" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_11_12" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_12_13" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_13_14" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_14_15" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_15_16" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_16_17" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_17_18" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_18_19" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_19_20" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_20_21" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_21_22" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_22_23" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="L_23_24" :class="'calendar_value_'+this.id + ' calendar'"></td>
                        </tr>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none">M</th>
                            <td id="M_00_01" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_01_02" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_02_03" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_03_04" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_04_05" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_05_06" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_06_07" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_07_08" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_08_09" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_09_10" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_10_11" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_11_12" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_12_13" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_13_14" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_14_15" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_15_16" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_16_17" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_17_18" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_18_19" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_19_20" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_20_21" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_21_22" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_22_23" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="M_23_24" :class="'calendar_value_'+this.id + ' calendar'"></td>
                        </tr>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none">M</th>
                            <td id="X_00_01" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_01_02" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_02_03" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_03_04" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_04_05" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_05_06" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_06_07" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_07_08" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_08_09" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_09_10" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_10_11" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_11_12" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_12_13" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_13_14" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_14_15" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_15_16" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_16_17" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_17_18" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_18_19" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_19_20" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_20_21" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_21_22" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_22_23" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="X_23_24" :class="'calendar_value_'+this.id + ' calendar'"></td>
                        </tr>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none">J</th>
                            <td id="J_00_01" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_01_02" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_02_03" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_03_04" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_04_05" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_05_06" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_06_07" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_07_08" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_08_09" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_09_10" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_10_11" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_11_12" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_12_13" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_13_14" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_14_15" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_15_16" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_16_17" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_17_18" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_18_19" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_19_20" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_20_21" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_21_22" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_22_23" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="J_23_24" :class="'calendar_value_'+this.id + ' calendar'"></td>
                        </tr>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none">V</th>
                            <td id="V_00_01" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_01_02" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_02_03" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_03_04" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_04_05" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_05_06" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_06_07" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_07_08" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_08_09" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_09_10" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_10_11" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_11_12" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_12_13" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_13_14" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_14_15" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_15_16" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_16_17" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_17_18" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_18_19" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_19_20" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_20_21" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_21_22" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_22_23" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="V_23_24" :class="'calendar_value_'+this.id + ' calendar'"></td>
                        </tr>
                        <tr>
                            <th style="text-align: center; position: sticky; left: 0px; border-left: none">S</th>
                            <td id="S_00_01" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_01_02" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_02_03" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_03_04" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_04_05" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_05_06" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_06_07" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_07_08" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_08_09" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_09_10" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_10_11" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_11_12" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_12_13" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_13_14" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_14_15" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_15_16" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_16_17" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_17_18" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_18_19" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_19_20" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_20_21" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_21_22" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_22_23" :class="'calendar_value_'+this.id + ' calendar'"></td>
                            <td id="S_23_24" :class="'calendar_value_'+this.id + ' calendar'"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button class="btn" :id="'closeModal'+this.id" style="background-color:grey;color:white" type="button" data-bs-dismiss="modal">Cerrar</button>
                <button class="btn btn-primary" style="background:#002f87 !important" type="button" @click="sendCalendar">Guardar</button>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}

th {
    background-color: #52b69a !important;
    color: white;
}

.modal-xl {
    max-width: 1330px !important;
}
</style>

<script>
import $ from "jquery";
import router from '@/router/index.js' // Vue router instance
import Vue from 'vue'

// Vue.forceUpdate();

export default {
    components: {},
    mounted: function () {
        this.$forceUpdate()
        window.addEventListener('load', () => {
            var rows = $('#tabla tbody tr');
            if (!rows[0]) {} else {
                $('#tabla tbody tr').on('click', 'td', function (i, row) {
                    let valueText = $(this).text()
                    if (valueText != "") {
                        $(this).css("background-color", "white");
                        $(this).text("");
                    } else {
                        $(this).css("background-color", "#25B4BD");
                        $(this).text("X").css("color", "white").css("text-align", "center").css("font-weight", "800");
                    }

                });
            }
        })
    },
    methods: {
        sendCalendar() {
            //se envia la informacion del calendario al back
            let array = new Array();
            $('.calendar_value').each(function (i, obj) {
                let data = new Object();
                if (this.textContent.length > 0) {
                    if(this.id.split("_")[0] == 'L'){
                        data.dia = "LUNES";
                    }else if(this.id.split("_")[0] == 'M'){
                        data.dia = "MARTES";
                    }else if(this.id.split("_")[0] == 'X'){
                        data.dia = "MIERCOLES";
                    }else if(this.id.split("_")[0] == 'J'){
                        data.dia = "JUEVES";
                    }else if(this.id.split("_")[0] == 'V'){
                        data.dia = "VIERNES";
                    }else if(this.id.split("_")[0] == 'S'){
                        data.dia = "SABADO";
                    }else if(this.id.split("_")[0] == 'D'){
                        data.dia = "DOMINGO";
                    }

                    data.hora_desde = this.id.split("_")[1] + ":00";
                    data.hora_hasta = this.id.split("_")[2] + ":00";
                    array.push(data);
                }
            })
            let obj = new Object();
            obj.rangoHorario = array;
            this.$store.dispatch("tramiteGenerico/sendCalendar", obj).then((response) => {
                $("#dataCalendar_" + this.id).val(response);
                this.setValuesInput();
                document.getElementById("closeModal"+this.id).click();
            }).catch((error) => {
                Vue.swal("Portal de Proveedoras/es", error.response.data, "error")
            })

            document.getElementById("closeModal"+this.id).click();
        },
        getDate(data) {

        },
        getIdtable() {
            if (this.id.includes("dataConsultCalendar")) {
                return "tabla_" + this.id;
            } else {
                return "tabla";
            }
        },
        setValuesInput() {
            //se setea la informacion en el input
            $("#" + this.id).val("");
            let dataCalendar = new Object();
            $('.calendar_value_'+this.id).each(function (i, obj) {
                if (this.textContent.length > 0) {
                    
                    if (this.id.includes('D')) {
                        dataCalendar.d = "D"
                    } else if (this.id.includes('L')) {
                        dataCalendar.l = "L"
                    } else if (this.id.includes('M')) {
                        dataCalendar.m = "M"
                    } else if (this.id.includes('X')) {
                        dataCalendar.mi = "X"
                    } else if (this.id.includes('J')) {
                        dataCalendar.j = "J"
                    } else if (this.id.includes('V')) {
                        dataCalendar.v = "V"
                    } else if (this.id.includes('S')) {
                        dataCalendar.s = "S"
                    }
                }
            })
            let key = Object.keys(dataCalendar);
            for (let i = 0; i < key.length; i++) {
                $("#" + this.id).val($("#" + this.id).val() + key[i].toUpperCase() + "*-");
            }
        },

    },
    computed: {
        dataCalendar: {
            get() {
                return this.$store.state.proveedor.dataCalendar;
            }
        },
    },
    props: {
        id: String,
        title: String,
        value_pasivo: Array,
        value_activo: Array,
        position: String,
    }
}
</script>
