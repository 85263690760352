<template>
<div>
    <!-- navbar -->
    <Navbar />
    <div id="layoutSidenav">
        <Menu />
        <Spinner></Spinner>
        <div id="layoutSidenav_content">
            <main>
                <!-- header -->
                <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div class="container-xl px-4">
                        <div class="page-header-content pt-4">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto mt-4">
                                    <h1 class="page-header-title">
                                        <div class="page-header-icon"><i  :class="getIconClass()"></i></div>
                                        {{title}}
                                    </h1>
                                    <div class="page-header-subtitle">{{desc}} 
                                        <br><a style="font-size:14px;" v-if="this.tramiteGenericoID.length != '' ">(Gesti&oacute;nID : {{this.tramiteGenericoID}})</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <!-- fin header -->
                <!-- body -->
                <div class="container-xl px-4 mt-n10">
                    <!-- Wizard card navigation-->
                    <div class="card">
                        <div class="card-header border-bottom">
                            <!-- Wizard navigation-->
                            <div class="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard" id="cardTab" role="tablist">
                                <CardButton v-for="(dato,index) in this.estados.estados" :key="dato.id" :title="dato.descripcion" :active="getActive(index+1)" :id="'paso'+index+1" :numberCard="getIndex(index)" />
                            </div>
                        </div>
                        <!-- Wizard body -->
                        <div class="card-body">
                            <div class="tab-content" id="cardTabContent">
                                <CamposGenerico v-for="(dato,index) in this.estados.estados" :key="dato.id" :titulo="dato.descripcion" :codigo_estado="dato.codigo" :id_estado="dato.deft_tramite_estado_id" :active="getActive(index+1)" :fields="dato.atributos" :end="estados.estados.length"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- fin body -->
            </main>
        </div>
    </div>

</div>
</template>

<style>

</style>

<script>
import router from '@/router/index.js' // Vue router instance
import Spinner from '../../../components/spinner'
import Vue from 'vue'
import Navbar from '../../../components/home/navbar/navbar'
import Menu from '../../../components/home/menu'
import CardButton from '../../../components/home/step/util/cardButtonNoHref'
import CamposGenerico from '../../../components/home/step/tramiteGenerico/camposGenericos'
export default {
    name: "Home",
    components: {
        Spinner,
        Navbar,
        Menu,
        CardButton,
        CamposGenerico,
    },
    mounted: function () {
        this.addMenu();
    },
    methods: {
        getIconClass(){
            return this.iconClass;
        },
        addMenu() {
            document.body.classList.remove("sidenav-toggled");
        },
        getActive(active) {
            if (active == this.activo) {
                return "active"
            }
            return ""
        },
        getIndex(index) {
            return (parseInt(index) + 1).toString();
        },
    },
    computed: {
        activo: {
            get() {
                return this.$store.state.tramiteGenerico.activo;
            },
            set(value) {
                this.$store.commit("tramiteGenerico/SET_ACTIVO", value);
            },
        },
        estados: {
            get() {
                return this.$store.state.tramiteGenerico.tramiteEstados;
            },
        },
        title: {
            get() {
                return this.$store.state.tramiteGenerico.tramiteSeleccionado;
            },
        },
        desc: {
            get() {
                return this.$store.state.tramiteGenerico.tramiteSeleccionadoDesc;
            },
        },
        tramiteGenericoID:{
            get(){
                return this.$store.state.tramiteGenerico.idTramiteGenerico;
            }
        },
        iconClass:{
            get(){
                return this.$store.state.tramiteGenerico.iconTramite;
            }
        }
    },
};
</script>
