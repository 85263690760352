/**
 * Función utilizada para diferir la ejecución multiple de otra funcion en un lapso de tiempo predeterminado. Preferentemente para evitar llamados excesivos a una API al tener que usar eventos.
 * @param {function} func 
 * @param {number} delay 
 * @returns function
 */
export function debounce(func, delay) {
    let debounceTimer
    return function () {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
}

/**
 * Difiere la ejecución de una función hasta que el usuario termine de escribir
 * @param {function} callback 
 * @param {number} timeoutDuration 
 * @param {typeof setTimeout} timeoutObject 
 * @returns void
 */

export function handleKeyUp(callback, timeoutDuration, timeoutObject){
    clearTimeout(timeoutObject);

    // Make a new timeout set to go off after the specified duration
    timeoutObject = setTimeout(() => {
        callback(); // Execute the provided function
    }, timeoutDuration);
}


/**
 * Valida si un numero es par o impar
 * @param {number} number 
 * @returns boolean
 */
export function isEven(number) {
    return number % 2 === 0 ? true : false;
}