import $ from 'jquery'
export default {
    
    methods: {},
    //setea la lista de provincias en el atributto
    loadSelect(obj,valueOption){
        let select = document.createElement("select");
        select.id = this.generateId(obj.descripcion)+'_'+this.generateId(obj.tipo);
        select.name = obj.valor;
        select.className  = "form-control send-data-register send-data-register-check";
        select.ariaLabel=obj.descripcion;
        //se crea el primero vacio
        var option = document.createElement("option");
        option.value = "";
        option.text = "";
        option.disabled="true"
        option.selected="true"
        select.appendChild(option);
        //recorro las provincias
        valueOption.forEach(function (valor, indice, array) {
            var option = document.createElement("option");
            option.value = valor.id;
            option.text = valor.descripcion;
            select.appendChild(option);
        })
        
        $("#provincia_" + this.generateId(obj.tipo)).replaceWith(select);
    },
    //valida que los caracteres ingresados sean solo numeros
    onlyNumber(obj){
        $("input[name='"+obj.valor+"']").on('input', function(e) {
            $(this).val($(this).val().replace(/[^0-9]/g, ''));
        });
    },


    // UTIL
    generateId(data) {
        if(data != null && data.length>0){
            return data.replaceAll(' ', '_').toLowerCase();
        }else{
            return data;
        }
    },
  };
  