<template>
<div :class="'tab-pane py-2 py-xl-2 fade show '+ this.active" id="nuevoRegistro" role="tabpanel" aria-labelledby="nuevoRegistro-tab">
    <div class="row justify-content-center">
        <div class="col-xxl-10 col-xl-11">
            <div class="row">
                <template v-for="(field,index) in visibleFields">
                    <div class="col-md-12" v-if="getBloque(field.portal_bloque,index)" :key="field.id + 'bloque'">
                        <br>
                        <h6 style="color:#1f3c7b"><i class="fas fa-th-large" style="font-size:18px"></i> {{primeraLetraMayus(getNameBloque(field.portal_bloque_titulo))}}</h6>
                        <hr>
                    </div>
                    <div :class="'mb-3 ps-3 pe-0 ' + getLong(field.portal_longitud_columna,field)" :key="field.id + 'campo'">
                        <label class="text-gray-600 small " v-if="field.tipo.toUpperCase().trim() != 'GRILLA'" :for="generateId(field.codigo)">{{getLabel(field)}} {{getObligatorio(field.portal_obligatorio)}}</label>
                        <input v-if="field.tipo.toUpperCase().trim() == 'TEXTO'" :value="field.valor" :class="getRequiredClass(field.portal_obligatorio) + getValidateClass(field.validacion) + ' form-control send-data-register_'+id_estado" :id="generateId(field.codigo)" type="text" placeholder="" @input="validateKeyRegularExpression($event,field.validacion)" :name="field.codigo" :aria-label="field.portal_leyenda" :readonly="getReadonly(field.portal_solo_lectura)" />
                        <textarea v-if="field.tipo.toUpperCase().trim() == 'TEXTOEXTENSO'" :value="field.valor" rows="4" :class="getRequiredClass(field.portal_obligatorio) + getValidateClass(field.validacion) + ' form-control send-data-register_'+id_estado" :id="generateId(field.codigo)" type="text" placeholder="" @input="validateKeyRegularExpression($event,field.validacion)" :name="field.codigo" :aria-label="field.portal_leyenda" :readonly="getReadonly(field.portal_solo_lectura)" />
                        <input v-else-if="field.tipo.toUpperCase().trim() == 'FECHA'" :value="field.valor" :class="getRequiredClass(field.portal_obligatorio) + getValidateClass(getDateRegexString()) + ' form-control send-data-register_'+id_estado" :id="generateId(field.codigo)" type="date" placeholder="" @input="validateKeyRegularExpression($event,getDateRegexString())" :name="field.codigo" :aria-label="field.portal_leyenda" />
                        <ArchivoMultiple :id="generateId(field.codigo)" v-else-if="field.tipo.toUpperCase().trim() == 'ARCHIVOMULTIPLE' || field.tipo.toUpperCase().trim() == 'ARCHIVO'" :type="field.tipo" :id_tramite="tramiteGenericoID" :field="field" :id_estado="id_estado" />
                        <div class="row" v-else-if="field.tipo.toUpperCase().trim() == 'DOMICILIO'">
                            <div class="col-md-11">
                                <input class="form-control" style="background-color:white" :id="generateId(field.codigo)" type="text" readonly placeholder="" :aria-label="field.portal_leyenda" />
                                <input :value="field.valor" :class="getRequiredClass(field.portal_obligatorio) + ' form-control send-data-register_'+id_estado" style="background-color:white" :name="field.codigo" :id="field.codigo" type="hidden" />
                            </div>
                            <div class="col-md-1 ps-0 align-self-center">
                                <button data-bs-toggle="modal" :data-bs-target="'#modalDireccion'+generateId(field.codigo)" class="btn btn-icon lift lift-sm" style="background-color:#25B4BD;width: 38px !important; height: 38px !important;" type="button">
                                    <i class="fas fa-edit" style="color:white;font-size:16px;"></i>
                                </button>
                                <ModalDireccion :id="generateId(field.codigo)" :codigo="field.codigo" :value="field.valor" />
                            </div>
                        </div>

                        <div class="row" v-else-if="field.tipo.toUpperCase().trim() == 'RANGO HORARIO'">
                            <div class="col-md-11">
                                <input class="form-control" style="background-color:white" :id="generateId(field.codigo)" type="text" readonly placeholder="" :aria-label="field.portal_leyenda" />
                                <input :value="field.valor" :class="getRequiredClass(field.portal_obligatorio) + ' form-control send-data-register_'+id_estado" style="background-color:white" :name="'dataCalendar_'+generateId(field.codigo)" :id="'dataCalendar_'+generateId(field.codigo)" type="hidden" />
                            </div>
                            <div class="col-md-1 ps-0 align-self-center">
                                <button data-bs-toggle="modal" :data-bs-target="'#modalCalendar'+generateId(field.codigo)" class="btn btn-icon lift lift-sm" style="background-color:#25B4BD;width: 38px !important; height: 38px !important;" type="button">
                                    <i class="fas fa-calendar-week" style="color:white;font-size:16px;"></i>
                                </button>
                                <ModalRangoHorario :id="generateId(field.codigo)" />
                            </div>
                        </div>
                        <div class="row" v-else-if="field.tipo.toUpperCase().trim() == 'GRILLA'">
                            <div class="col-md-12">
                                <GrillaGenerica :dato="field.option" :valor="field.valor" :idTramite="tramiteGenericoID" :codigoAtributo="field.codigo" :field="field" />
                            </div>
                        </div>
                        <div class="row" v-else-if="field.tipo.toUpperCase().trim() == 'ITEMS'">
                            <div class="col-md-12">
                                <GrillaItems :dato="field.option" :id_estado="id_estado" :id="generateId(field.codigo)" :valor="field.valor" :idTramite="tramiteGenericoID"  :codigoAtributo="field.codigo" />
                            </div>
                        </div>
                        <select v-else-if="field.tipo.toUpperCase().trim() == 'SELECTOR'" :class="getRequiredClass(field.portal_obligatorio) + ' form-control send-data-register_'+id_estado" style="height:50px" :id="generateId(field.codigo)" placeholder="" :name="field.codigo" :aria-label="field.portal_leyenda">
                            <option v-for="(dato) in field.option" :key="dato.valor" :value="dato.valor" :selected="getOptionSelect(field.valor,dato.valor)">{{dato.descripcion}}</option>
                        </select>
                        <template v-else-if="field.tipo.toUpperCase().trim() == 'ETIQUETA'">
                            <div class="row mt-4">
                                <div class="col-md-12"> <label class="text-gray-500 small">{{field.portal_leyenda}}</label></div>
                            </div>
                        </template>
                    </div>
                </template>
            </div>

            <hr class="mt-1">
            <div class="row gx-3">
                <div class="col-md-12 text-end mt-4 mb-md-0">
                    <a v-if="this.activo != 1" :class="this.backButtonClass" :id="'btn_volver_'+codigo_estado" @click="stepBack">Volver</a>
                    <a class="btn btn-primary color-btn lift lift-sm btn-next-generico" @click="nextStep">{{this.continueButtonText}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.btn-next-generico {
    background-color: #002f87 !important;
    border: none;
}

.title {
    color: black !important;
    cursor: auto;
}

.btn-icon-mio {
    height: calc((0.75rem * 2) + (0.5rem * 2) + (2px));
    width: calc((0.75rem * 2) + (0.5rem * 2) + (2px));
}

/*Estilos para invalidar campos*/
.invalid-field {
    border-color: var(--bs-red);
}

.invalid-field:focus {
    border-color: var(--bs-red);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(220 53 70 / 25%);
}

.btn_back_wf {
    display: none !important;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import UtilJS from '../../../../assets/js/util'
import $ from 'jquery'
import GenericosJS from '../../../../assets/js/utilGenericStep'
import ModalDireccion from '../../step/util/modalDireccion'
import ModalRangoHorario from '../util/modalCalendar'
import GrillaGenerica from '../util/grillaGenerica'
import Vue from 'vue'
import ArchivoMultiple from '../tramiteGenerico/campoArchivoMultiple'
import GrillaItems from '../../step/util/grillaItems'
let bloque = 0;
export default {
    mixins: [UtilJS, GenericosJS],
    components: { ModalDireccion, ModalRangoHorario, GrillaGenerica, ArchivoMultiple, GrillaItems },
    mounted: function () {
        this.$store.commit("tramiteGenerico/SET_ACTIVO", 1);
        this.$store.commit("SET_BLOQUEADO", false);
        this.iniciarBloque();
    },
    methods: {

        getLabel(field) {
            if (field.tipo.toUpperCase().trim() == 'ETIQUETA') {
                return "";
            } else {
                return field.portal_leyenda;
            }
        },
        getRequiredClass(obligatorio) {
            if (!(obligatorio.trim().toUpperCase() == 'NO')) {
                return ' send-mandatory-field-' + this.id_estado
            } else {
                return '';
            }
        },
        getDateRegexString() {
            return '^\\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$';
        },
        getValidateClass(regExpString) {
            if (!regExpString || regExpString == '') {
                return ''
            }
            return ' send-validate-field-' + this.id_estado
        },
        getOptionSelect(valorOption, datoIn) {
            if (datoIn != null && valorOption == datoIn) {
                return true;
            } else {
                return false;
            }
        },
        getVisible(field) {
            if ("NO" != field.visible.toUpperCase()) {
                if (field.portal_leyenda.toUpperCase().trim() == "BACK_WF") {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        getObligatorio(atributte) {

            if (atributte != null && (atributte.toUpperCase() == "SI" || atributte.toUpperCase() == "YES")) {
                return "(*)"
            } else {
                return ""
            }
        },
        getReadonly(atributte) {
            if (atributte != null && atributte.toUpperCase() == "NO") {
                return false;
            } else {
                return true;
            }
        },
        getValidationFileExtension(validacion, id) {
            if (validacion != null || validacion.length > 1) {
                let name = $("#" + id).val();
                if (this.validateInputFile(validacion, id, name) == 0) {
                    $("#" + id).val("");
                    Vue.swal("Portal Proveedores/as", "La extensión del archivo no es valida.<br> Las extensiones válidas son : " + validacion, "info")
                }
            }
        },
        validateInputFile(validacion, id, name) {
            let dataValidation = validacion.split(",");
            let flag = 0;
            dataValidation.forEach(element => {
                if (element.trim() == "." + name.split(".")[1].toUpperCase().trim()) {
                    flag = 1;
                }
            });
            return flag;
        },
        getValidationFile(validacion) {
            if (validacion == null || validacion.length < 1) {
                return "*";
            } else {
                return validacion;
            }
        },
        validateKeyRegularExpression(event, validacion) {
            if (validacion == null || validacion.length < 1) {
                return true;
            }

            const invalidationClass = 'invalid-field'

            if (event.target.value == '' || !event.target.value) {
                $(event.target).removeClass(invalidationClass)
                return true
            }
            const regex = new RegExp(validacion);

            if (!regex.test(event.target.value)) {
                $(event.target).addClass(invalidationClass)
                return false;
            }
            $(event.target).removeClass(invalidationClass)
            return true;
        },
        getNameBloque(data) {
            if (data != null && data.length > 0) {
                return data.replaceAll('_', ' ');
            }
            return data;
        },
        iniciarBloque() {
            bloque = 0;
        },
        getBloque(bloqueIn, index) {
            if (index == 0) {
                bloque = "1";
                return true;
            }
            if (bloqueIn == bloque) {
                return false;
            } else {
                bloque = bloqueIn;
                return true;
            }
        },
        getLong(long, field) {
            if (field.tipo != null && field.tipo.toUpperCase().trim() == "RANGO HORARIO") {
                return "col-md-8";
            }
            if (field.tipo != null && field.tipo.toUpperCase().trim() == "ETIQUETA") {
                return "col-md-8";
            }
            if (field.tipo != null && field.tipo.toUpperCase().trim() == "ARCHIVO" || field.tipo.toUpperCase().trim() == "ARCHIVOMULTIPLE") {
                return "col-md-8";
            }
            if (field.tipo != null && field.tipo.toUpperCase().trim() == "DOMICILIO") {
                return "col-md-8";
            }
            if (field.tipo != null && field.tipo.toUpperCase().trim() == "GRILLA" || field.tipo.toUpperCase().trim() == "ITEMS") {
                return "col-md-12";
            }
            if (long == "1") {
                return "col-md-4";
            } else if (long == "2") {
                return "col-md-8";
            } else {
                return "col-md-12";
            }
        },
        generateId(data) {
            return data.replaceAll(' ', '_').toLowerCase();
        },
        nextStep() {
            try {
                //Verificar si hay algun campo obligatorio vacio
                $('.send-mandatory-field-' + this.id_estado).each(function (i, obj) {
                    if (this.value == null || this.value == "" || this.value.length == 0 || this.value == 0) {
                        throw ("El campo " + this.ariaLabel + " es obligatorio.")
                    }
                });
                //Verificar si hay algun campo (de texto) que requiera validacion y sea invalido.
                $('.send-validate-field-' + this.id_estado).each(function (i, obj) {
                    if (obj.className.indexOf('invalid-field') !== -1)
                        throw ("El campo " + this.ariaLabel + " es inválido.")
                });

                //Verificar si hay algun campo (de texto) que requiera validacion y sea invalido.
                $('.send-validate-field-' + this.id_estado).each(function (i, obj) {
                    if (obj.className.indexOf('invalid-field') !== -1)
                        throw ("El campo " + this.ariaLabel + " es inválido.")
                });

                //Verifica si hay algun archivo en proceso de carga.
                $('.file-upload-tmp-' + this.id_estado).each(function (i, obj) {
                    if (this.value != '') {
                         throw ("Debe esperar a que finalice la carga del archivo enviado.")
                    }
                });

                let step = this.activo;
                let codigo_estado = this.codigo_estado
                let sendData = new Object();
                let array = new Array();
                sendData.id = this.tramiteGenericoID;
                sendData.deft_tipo_estado_id = this.id_estado;
                $('.send-data-register_' + this.id_estado).each(function (i, obj) {
                    let data = new Object;
                    data.codigo_estado = codigo_estado;
                    data.codigo_atributo = this.name
                    data.valor = this.value;
                    array.push(data);
                })
                //si existe la tabla items la va a guardar
                let functions = this;
                $('.send_table_items_' + this.id_estado).each(function (i, obj) {
                    let data = new Object;
                    data.codigo_estado = codigo_estado;

                    data.codigo_atributo = this.id.split('_')[1].toUpperCase()
                    data.valor = functions.getValueItemGrilla(this.id);
                    array.push(data);
                })
                sendData.atributos = array;
                this.$store.dispatch("tramiteGenerico/sendTramiteGenerico", sendData).then((response) => {
                    if (this.end == step || this.forceEnd) {
                        router.push("/home")
                    } else {
                        this.$store.dispatch("tramiteGenerico/sendTramite", this.getDataTramiteData()).then((response) => {
                            this.$store.commit('tramiteGenerico/SET_TRAMITE_ESTADO', response);
                            this.$store.commit("tramiteGenerico/SET_ID_TRAMITE_GENERICO_ID", response.tramite_id);
                            this.$store.commit('SET_BLOQUEADO', false);
                            this.$store.commit("tramiteGenerico/SET_ACTIVO", parseInt(step) + 1);
                        }).catch((error) => {
                            Vue.swal("Portal de Proveedoras/es", error.response.data, "error")
                        })

                    }
                }).catch((error) => {
                    Vue.swal("Portal de Proveedoras/es", JSON.stringify(error.response.data.mensaje), "error")
                })

            } catch (error) {
                Vue.swal("", error, "info")
            }
        },
        getValueItemGrilla(id) {
            let array = new Array;
            let obj = new Object;
            var table = document.getElementById(id);
            for (let i in table.rows) {
                let row = table.rows[i]
                for (let j in row.cells) {
                    if(row.cells[j].dataset != null && row.cells[j].dataset.label != null){
                        obj[row.cells[j].dataset.label] = row.cells[j].children[0].value
                    }
                }
                if(!(Object.keys(obj).length === 0)){
                    array.push(obj);
                    obj = new Object;
                }
            }
            return JSON.stringify(array);
        },
        isEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
        stepBack() {
            let step = this.activo;
            this.iniciarBloque();
            if (step == 1) {
                router.push("/home")
            } else {
                this.$store.dispatch("tramiteGenerico/sendTramite", this.getDataTramiteData()).then((response) => {
                    this.$store.commit('tramiteGenerico/SET_TRAMITE_ESTADO', response);
                    this.$store.commit("tramiteGenerico/SET_ID_TRAMITE_GENERICO_ID", response.tramite_id);
                    this.$store.commit('SET_BLOQUEADO', false);
                    this.$store.commit("tramiteGenerico/SET_ACTIVO", parseInt(step) - 1);
                }).catch((error) => {
                    Vue.swal("Portal de Proveedoras/es", error.response.data, "error")
                })
            }
        },
        getDataTramiteData() {
            let obj = new Object;
            obj.id = this.tramiteGenericoID;
            obj.cart_usuario_id = this.user;
            obj.cart_empresa_id = this.$store.state.user.user.empresa.id
            return obj;
        },
    },
    computed: {
        visibleFields(){
            return this.fields.filter((field) => {
                if ("NO" != field.visible.toUpperCase()) {
                    return field.portal_leyenda.toUpperCase().trim() == "BACK_WF" ? false : true;
                } else {
                    return false;
                }
            });
        },
        activo: {
            get() {
                return this.$store.state.tramiteGenerico.activo;
            }
        },
        tramiteGenericoID: {
            get() {
                return this.$store.state.tramiteGenerico.idTramiteGenerico;
            }
        },
        user: {
            get() {
                return this.$store.state.user.user.id;
            },
        },
        backEnabled: {
            get() {
                let back_wf = 0;
                this.fields.forEach(element => {
                    if (element.portal_leyenda.toUpperCase().trim() == 'BACK_WF' && element.valor != null && element.valor.toUpperCase().trim() == 'NO') { 
                        back_wf = 1; 
                    }
                })
                return back_wf == 0;
            }
        },
        backButtonClass: {
            get() {
                return {'btn':true, 'btn-primary':true, 'lift':true, 'lift-sm':true, 'me-3':true, 'btn-back':true, 'btn_back_wf': !this.backEnabled};
            }
        },
        forceEnd: {
            get() {
                let forceEnd_wf = 0;
                this.fields.forEach(element => {
                    if (element.portal_leyenda.toUpperCase().trim() == 'FORCEEND_WF' && element.valor != null && element.valor.toUpperCase().trim() == 'SI') { 
                        forceEnd_wf = 1; 
                    }
                })
                return forceEnd_wf == 1;
            }
        },
        continueButtonText: {
            get() {
                return this.forceEnd? 'Finalizar' : 'Continuar';
            }
        }
    },
    props: {
        fields: Array,
        active: String,
        end: Number,
        codigo_estado: String,
        id_estado: String | Number,
        titulo: String,
        id_user: String,
    }
}
</script>>
