<template>
    <div class="mt-2">
        <div class="virtualTable root" :style="{ width: tableWidth + 'px' }" ref="root" >
            
            <div class="virtualTable__head" :class="{ 'pe-0': !tableOverflow }" :style="{ width: tableWidth + 'px' }">
                <div class="virtualTable__headItem" :class="item.class" v-for="(item,index) in tableConfig" :key="item.prop + '_' + index">
                    <span class="d-flex justify-content-center align-items-center" v-if="item.actionType == 'check'"> 
                        <input v-tooltip="item.name" type="checkbox" :checked="allChecked(item.actionName)" :name="item.actionName" @change="onSelectAllHandler(item.actionName)" id="">
                    </span>
                    <span v-else >{{item.name}}</span>
                </div>
            </div>
            <div class="virtualTable__body spacer" :style="{ width: tableWidth + 'px' }" ref="spacer">
                <DynamicScroller
                    ref="dynamic-scroller"
                    class="scroller"
                    :style="{ 'max-height': tableHeight + 'px' }"
                    :items="scrollerItems"
                    :min-item-size="40"
                    key-field="tableId"
                    skipHover
                >

                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                        :item="item"
                        :active="active"
                        :data-index="index"
                    >
                    <div class="virtualTable__bodyRow" :class="{'-odd': !isEven(index)}" :data-index="item.id" :key="item.id" :data-id_row="item.id">
                        
                        <template v-for="config in tableConfig" >
                            <template v-if="config.prop == '_index'">
                                <span  class="virtualTable__bodyItem" :class="config.class" :key="config.prop + '_' + item['id']">{{ item['id'] }}</span>
                            </template>
                            <template v-else-if="config.prop == '_navigation'">
                                <span data-label="" class="virtualTable__bodyItem" :key="'navi_' + config.prop + '_' + item['id']"><button @click="$emit('navigation', index)" class="btn btn-primary color-btn lift lift-sm" style="background-color:rgb(0, 47, 135) !important;border:none;height:33px;cursor:poiner !important;" title="Generar Gestión"><i class="fas fa-share-square"></i></button></span>
                            </template>
                            <template v-else v-for="(value,key) in item">
                                <span v-html="value" class="virtualTable__bodyItem" :class="config.class" v-if="config.prop == key" :key="config.prop + '_' + item['id']"></span>
                            </template>
                        </template>
                    </div>
                    </DynamicScrollerItem>
                </template>
                </DynamicScroller>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Componente Table, una tabla de datos 
 * 
 * Referirse a la carpeta ./examples para verificar la estructura de los parametros.
 * Estilos globales en /assets/scss/_virtualTable.scss
 * 
 * @prop { object[] } tableData - Datos de la tabla
 * @prop { object[] } tableConfig - Cabecera de la tabla
 * @prop { string } maxRows - Cantidad de filas máximas visibles en la grilla
 * @prop { boolean } search - Define si posee un campo para filtrar los datos
 */

import { DynamicScroller , DynamicScrollerItem } from 'vue-virtual-scroller';
import detectElementOverflow from 'detect-element-overflow'
import { isEven } from '@/utils/helpers'

export default {
    name: 'Table',
    components: {
        DynamicScroller,
        DynamicScrollerItem
    },
    props: { 
        tableData: Array,
        tableConfig: Array,
        maxRows: Number,
        search: Boolean,
    },
    data() {
        return {
            scrollerItems: [],
            searchQuery: '',
            tableOverflow: false
        }
    },
    mounted: function () {
        this.scrollerItems = JSON.parse(JSON.stringify(this.$_tableData));
    },
    updated: function(){
        this.checkOverflow();
    },
    methods: {
        isEven: isEven,
        checkOverflow(){
            const parent = this.$refs['spacer'].children[0];
            const child = Array.from(parent.children).find(el => el.classList.contains('vue-recycle-scroller__item-wrapper'))

            const { collidedTop, collidedBottom } = detectElementOverflow(child, parent);

            this.tableOverflow = (collidedTop || collidedBottom);
        }
    },
    watch: {
        searchQuery() {
            this.updateData();
        },
        $_tableData() {
            this.scrollerItems = JSON.parse(JSON.stringify(this.tableData));
        },
        tableHeight(){
            this.checkOverflow();
        }
    },
    computed: {
        $_tableData(){
            return this.tableData;
        },
        tableWidth(){
            let totalWidth = 20;

            this.tableConfig.forEach((col) => {
                let indexWidth = 40;
                let dataWidth = 120;
                if(col.prop === '_index'){
                    totalWidth += indexWidth
                }else{
                    totalWidth += dataWidth
                }
                
            });

            return totalWidth;
        },
        tableHeight(){
            return 40 * this.maxRows;
        },
    }
}
</script>
<style lang="scss" scoped>
    $colWidth: 120px;

    .scroller {
        overflow: auto;
        width: 100%;
        min-width: 100%;
        height: 100%;
    }

    .virtualTable {
        color: #606266;
        min-width: 100%;
        font-size: 12px;
        overflow-y: hidden;
        overflow-x: scroll;
        display: flex;
        flex-direction: column;
        max-width: 100%;

        &__search {
            input {
                border-color: #ced0d6 !important;
            }
        }

        &__head {
            min-width: 100%;
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            height: 50px;
            border: 1px solid #ebeef5;
            background: #52b69a;

            @media(min-width: 768px) {
                padding-right: 17px;
            }

            &Item {
                text-align: center;
                width: 100%;
                min-width: $colWidth;
                max-width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #fff;
                border-right: 1px solid #ebeef5;
                padding: 0px 5px;



                &.-index {
                    max-width: 40px;
                    min-width: 40px;
                }

                &.-check {
                    max-width: 30px;
                    min-width: 30px;
                }
            }
        }

        &__body {
            border: 1px solid #ebeef5;
            min-width: 100%;

            .vue-recycle-scroller__item-view {
                .virtualTable__bodyRow.-odd {
                    background: #f8f9fb;
                }
            }

            &Row {
                display: flex;
                flex-grow: 0;
                flex-shrink: 0;
                flex-direction: row;
                align-items: stretch;
                justify-content: flex-start;
                min-height: 40px;
                border-bottom: 1px solid #ebeef5;
                transition: 200ms ease;

                &:hover {
                    background: #ebeef5 !important;
                }

            }

            &Item {
                text-align: center;
                width: 100%;
                min-width: $colWidth;
                max-width: 100%;
                display: flex;
                padding: 5px 5px;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-right: 1px solid transparent;


                &.-index {
                    max-width: 40px;
                    min-width: 40px;
                    background-color: #ebeef5;
                }

                &.-check {
                    max-width: 30px;
                    min-width: 30px;
                }

                &.-input {
                    display: flex;
                    justify-content: center;
                }

                input {
                    font-size: 12px;

                    &:focus {
                        box-shadow: none;
                        border: 2px solid #002f87;
                    }
                }
            }
        }
    }
</style>