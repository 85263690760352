<template>
<div>
    <div>
        <table :id="'tableItems_'+this.id" :class="'dataTable-table table-striped table-mobile w-100 mt-1 send_table_items_'+this.id_estado">
            <thead>
                <tr>
                    <th data-sortable="" style="font-size:15px;vertical-align: middle;" class="text-center w-100" v-for="(dato) in this.dato" :key="dato.valor">{{cleanReadOnly(dato.descripcion)}} </th>
                </tr>
            </thead>
              <tbody v-if="valor.length>2" >
                <tr class="tr-background" v-for="(objRow,index) in getJsonParse(valor)" :key="index">
                    <td  v-for="(atributo) in  dato" :key="atributo.valor" :data-label="atributo.valor">
                        <textarea :class="getClassTd(atributo, objRow)" :readonly="getReadOnly(atributo)" placeholder="" v-model="objRow[atributo.valor]"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</template>

<style>
.title {
    color: black !important;
    cursor: auto;
}

.not-resizeable {
    resize: none;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    components: {},
    mounted: function () {},
    data() {
        return {
            fieldSizes: []
        }
    },
    methods: {
        cleanReadOnly(desc){
            return desc.replaceAll('&','');
        },
        getJsonParse(valor){
            if(valor != null && valor.length>2){
                return JSON.parse(valor);
            }else{
                return valor;
            }
        },
        getReadOnly(obj){
            if(obj.descripcion.includes("&")){
                return true;
            }
            return false;
        },
        getAlignTd(atributo, objRow) {
            try {
                if (isNaN(objRow[parsedValue])) {
                    return "text-start"
                }else{
                    return "text-end"
                }
                
            } catch (error) {
                return "text-start"
            }
        },
        getClassTd(atributo, objRow) {
            let parsedValue = atributo.valor.toUpperCase().replaceAll(' ','_');
            try {
                if (isNaN(objRow[parsedValue])) {
                    return "form-control send-data-register not-resizeable text-start";
                }else{
                    return "form-control send-data-register not-resizeable text-end";
                }
                
            } catch (error) {
                return "form-control send-data-register not-resizeable text-start";
            }
        },
    },
    computed: {},
    props: { dato: Array, valor: String, idTramite: Number, codigoAtributo: String, id:String,id_estado:String }
}
</script>
